export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/favicon-16x16.png"},{"rel":"manifest","href":"/favicon/site.webmanifest"}],"style":[],"script":[{"src":"https://widget.cloudinary.com/v2.0/global/all.js"}],"noscript":[],"title":"itsaSIforRIA"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null